
import { defineComponent } from 'vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import PageContentSidebarHr from '@/components/layout/pageContent/sidebar/PageContentSidebarHr.vue'
import { reportingSidebarRoutes, reportingComingSoonSidebarRoutes } from '@/router/sidebarRoutes/reportingRoutes'

export default defineComponent({
  components: {
    PageContentSidebarList,
    PageContentSidebarHr,
  },
  setup() {
    return {
      reportingSidebarRoutes,
      reportingComingSoonSidebarRoutes,
    }
  },
})
